/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import { HashLink } from 'react-router-hash-link';
// import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton';
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import MatchContent from '../../components/MatchContent'
import Collapsible from 'react-collapsible';
//import StrategiesContent from '../../components/StrategiesContent'
//import ShortStrategiesContent from '../../components/ShortStrategiesContent'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class StrategyView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Strategy')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = StrategyView

        return Controller
      }

      throw e
    }
  }

  state = {
    noneOpen1: true,
    noneOpen2: true,
    noneOpen3: true,
  };

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    // const proxies = StrategyView.Controller !== StrategyView ? transformProxies(this.props.children) : {

    // }
    // const buttonTogetherProps = {
    //   image:"contact-white.png",
    //   displayText:"Working Together",
    //   link: "strategy.html",
    // }
    // const buttonPurposeGrowthProps = {
    //   image:"ideas-white3x.png",
    //   displayText:"Purpose & Growth",
    //   link: "impact.html",
    // }

    const buttonScrollToClimateTechSectionProps = {
      link:"strategy#strategies",
      webflowanimate:false,
    }
    const buttonScrollToIndustryTechSectionProps = {
      link:"strategy#industrytech",
    }
    const buttonScrollToSocialTechSectionProps = {
      link:"strategy#socialimpacttech",
    }
    const buttonScrollToHowSectionProps = {
      link:"strategy#how",
    }
    const buttonScrollToTopSectionProps = {
      link:"strategy#top",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/leapsolve.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader />
              <div id="wematch" className="af-class-section af-class-home-hero af-class-wf-section bg-[url('../images/Background_Forest_Masked.jpg')] bg-cover animate-xappear min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container text-gray-300 content-center items-center place-content-center">
                  <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                    <h2 className="af-class-display-2 inline text-leapsolvesecond-normal font-bold text-center animate-xappearfadein">Transparent Approach</h2>                
                    <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                      <div className="af-class-fill-block af-class-bg-neutral-100 bg-leapsolvesecond-normal opacity-50" />
                    </div> 
                  </div> 
                  <div className="af-class-paragraph-large af-class-manifesto-hero-paragraph text-white animate-xappearfadein">
                    <p className="af-class-paragraph-large max-w-[70vw] mt-8 self-stretch items-center justify-center align-middle text-center mx-auto">
                      Healthy relationships rely on transparency, so we gathered essential attributes of our approach for you to check how greatly we might fit.
                      You can further explore hereafter our investment themes.
                    </p>
                  </div>
                  <MatchContent 
                      button1Text="Investors"
                      button2Text="Founders"
                      nbCols={3}
                      cards1={[
                        {
                          title:"Early-stage Ventures",
                          description: "For you early-stage startups",
                          list:["Represent unique opportunities","Require specific expertise","Are medium to long term investments"]
                          // description: "Early stage companies bear interest to you. You understand the risks, but also understand that future unicorns must be grown."
                        },
                        {
                          title:"Positive Impact",
                          description: "Impact investing",
                          list:["Is part of your objectives","Is new to you","or well-known but goes beyond ESG"]
                        },
                        {
                          title:"Data-Driven",
                          description: "You want",
                          list:["Data supported investment decisions", "To obtain impact and financial data","To collaborate on your reporting needs"]
                        },
                        {
                          title:"Partnership",
                          description: "You envision investing",
                          list:["To access specific screening expertise","To enable co-investments","To build your future deal pipeline"]
                        },
                        {
                          title:"DEI",
                          description: "When it comes to DEI",
                          list:["Startups must promote these values","Fund qualification is required"]
                        },
                        {
                          title:"Geography",
                          description: "Your focus is",
                          list:["To invest in the US and/or Europe","On finding investments with revenues on global markets"]
                        }
                      ]}
                      cards2={[
                        {
                          title:"Mission",
                          description: "Your mission fits within",
                          list:["At least one of our three themes below","One or more UN SDG","or follow other impact standards"]
                          // description: "You are active fighting climate change, or bringing widespread social value, or maybe transforming our industries. You might already be framing your project addressing one or more UN SDG, or adhering to rising impact networks such as the Impact Management Project or B-corp principles"
                        },
                        {
                          title:"Sustainable Business",
                          description: "You agree with",
                          list:["Impact implies scale","Business sustainability implies planning","Purpose and profit are compatible"]
                          // description: "As we do, you believe that impact implies scale. It means that you plan to grow. You want to drive according to your values and create a mission-driven culture, but making sure that your venture is sustainable and brings returns to all shareholders."
                        },
                        {
                          title:"Tech",
                          description: "Digital or manufacturing tech is",
                          list:["At the core of your venture","Your proprietary IP","or you harness multiple techs"]
                          // description: "Your own tech or harnessing multiple techs in a convergent solution is at the core of your venture. It might be digital or manufactured tech, as long as scalability and reach are important for you."
                        },
                        {
                          title:"Leadership",
                          description: "You are self-aware and consider",
                          list:["An empowering culture being key","Team diversity as an asset","Partners and mentors as enablers"]
                          // description: "You know your strengths and blind spot. You lead relying on a strong and diverse team, but further look for external partners, experts and/or mentors to help you reach your goals."
                        },
                        {
                          title:"Fund Raising",
                          description: "You are raising",
                          list:["A Seed/Post-Seed round","or a Series A, eventually B","Looking for founders-friendly structures"]
                          // description: "You are raising a Seed/Post-Seed, Series A, or you are preparing a Series B. You might be looking for creative financing options which might be less conventional and more founders-friendly or path-aware. You might not have recurring revenues yet, but let's be clear, we will need to be in-sync on your 12 months strategy to get there."
                        },
                        {
                          title:"Geography",
                          // description: "Your startup:\n operates in the US or Europe\n or you plan to expand in the US\n creates products or solutions with global reach."
                          description: "Your startup",
                          list: ["Operates in the US or Europe","or you plan to expand in the US","Creates solutions with global reach"],
                          // description: "You operate in the US or Europe, but if your solutions can help in any region of the world, it is even better. Alternatively, you plan to reach at least the US markets and search for an expansion partner."
                        }
                      ]}
                    />
                </div>
                <ScrollSectionButton {...buttonScrollToClimateTechSectionProps} />
              </div>
              {/* <div id="strategies" className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section bg-[url('../images/Background_Forest_Masked.jpg')] bg-cover">
                <div className="af-class-container-3 w-container">
                  <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleondark text-center">
                    Investment Themes & Domains
                  </h2>                
                  <p data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" className="af-class-paragraph-medium max-w-[70vw] mt-8 self-stretch items-center justify-center align-middle text-center mx-auto">
                    Building up on our own expertise and network, Altalurra currently focuses on three key and interconnected themes across which a strong ecosystem effect can benefit all stakeholders. Within each, a few core and opportunistic domains have our particular attention - this is a list in constant evolution.&nbsp;
                    <HashLink smooth data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342421" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} to="/strategy#wematch" aria-current="page" className="af-class-button-primary af-class-medium w-inline-block w--current text-leapsolvesecond-normal hover:text-leapsolvethird-normal align-bottom mx-2">
                          <div>Shall we work together?<span className="af-class-button-arrow"></span></div>
                    </HashLink>
                  </p>
                </div>
                <div data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" className="af-class-container-default max-w-[90vw] mt-4">
                  <StrategiesContent />
                </div>
              </div> */}
              <div id="strategies" className="af-class-section af-class-wf-section min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container content-center items-center place-content-center">
                  <div>
                    <div className="af-class-_2-column-grid af-class-larger-right-column self-center place-self-center">
                      <div>
                        <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="af-class-dash---130px af-class-mg-bottom-32px">
                          <div className="af-class-fill-block af-class-bg-primary-1" />
                        </div>
                        <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleonwhite">Climate Tech</h2>
                        <img data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} src="images/All_Climate@3x.png" alt="Climate" width={200} className="inline mt-[3vh]" />      
                      </div>
                      <div className="af-class-div-block-2 af-class-color-neutral-800 content-center">
                        <div className="flex flex-col gap-y-6">
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen1: false})}
                          onClose={() => this.setState({ noneOpen1: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen1 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Decarbonization</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Decarbonization</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >            
                          <div>   
                            <img src="images/Decarb@3x.png" alt="Carbon" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              Decarbonization is a far-reaching term which routinely encompasses most of initiatives with a stated positive impact on rising temperatures stemming from emissions.
                              We aim at supporting any initiative enabling or backed by full life cycle assessment: 
                            </p>
                            <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>long term sustainability projects; e.g. alternative construction materials</li>
                              <li>ventures enabling emitted carbon and greenhouse gas removal; e.g. algae cultures</li>
                              <li>technologies and platforms enabling decarbonization monitoring; e.g. reforestation efficiency measurement</li>
                            </ul>
                          </div> 
                        </Collapsible>
                        </div>                      
                        <div
                        >                        
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen1: false})}
                          onClose={() => this.setState({ noneOpen1: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen1 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Greenwashing prevention</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Greenwashing prevention</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Greenwashing@3x.png" alt="Greenwashing" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              Preventing greenwashing - whether conscious or unconscious - is key in our fight against climate change. We back companies providing technologies for capturing, auditing, tracking and generally providing transparency to regulators and all stakeholders. This includes for instance:                              
                            </p>
                            <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>advanced automated data capture devices</li>                              
                              <li>specialized blockchain for tracking tree planting</li>
                              <li>technologies enabling product life cycle tracking from manufacturing to recycling</li>
                            </ul>
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen1: false})}
                          onClose={() => this.setState({ noneOpen1: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen1 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Accounting, impact assessment and enablement</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Accounting, impact assessment and enablement</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Measurement@3x.png" alt="Measurement" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              Impact measurement is likely the greatest challenge we all face. It needs to be ground on comprehensive analysis and transparency in assumptions. It shall further be include stress testing capabilities and real-time decision-making tools, in a similar fashion as the financial industry has been applying to financial markets for decades. 
                              We aim to back startups:
                            </p>
                            <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>coming up with relevant impact data and measurement frameworks</li>                              
                              <li>empowering public and private companies to take impact-based decisions</li>
                              <li>raising awareness and assisting consumers through their journey</li>
                            </ul>
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen1: false})}
                          onClose={() => this.setState({ noneOpen1: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen1 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Biodiversity offsets, nature-based and carbon offsets</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Biodiversity offsets, nature-based and carbon offsets</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Offsets@3x.png" alt="Measurement" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              As we go through a global transformation, we need to recognize that particular companies will not be able to change their operations in a timely fashion. Offsets are important tools to put in place in order to foster the generation of net positive impact ventures, while creating the right economic incentives for emission positive companies. If you are creating tools enabling transparent offsets marketplaces, or enablement platform for net positive projects, please get in touch.
                            </p>
                          </div> 
                        </Collapsible>
                        </div>
                        </div>
                      </div> 
                    </div>
                    <p className="af-class-paragraph-large mt-20" data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>                   
                      The above examples are at the core of the ecosystem which we try to promote and constitute the infrastructure needed for all climate related initiatives. There is a lot more to nurture, so feel free to contact us if you are on a mission to provide hard or soft technologies to fight climate change
                    </p>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToIndustryTechSectionProps} />
              </div>
              <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="relative min-w-[100vw] h-[2px]">
                <div className="af-class-fill-block bg-leapsolvethird-normal" />
              </div>
              <div id="industrytech" className="af-class-section af-class-wf-section min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container content-center items-center place-content-center">
                  <div>
                    <div className="af-class-_2-column-grid af-class-larger-right-column self-center place-self-center">
                      <div>
                        <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="af-class-dash---130px af-class-mg-bottom-32px">
                          <div className="af-class-fill-block af-class-bg-primary-1" />
                        </div>
                        <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleonwhite">Industry & Infrastructure Tech</h2>
                        <img data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} src="images/All_Industry@3x.png" alt="Industry" width={200} className="inline mt-[3vh]" />      
                      </div>
                      <div className="af-class-div-block-2 af-class-color-neutral-800 content-center">
                      <div className="flex flex-col gap-y-6">
                        <div
                        >                        
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen2: false})}
                          onClose={() => this.setState({ noneOpen2: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen2 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Decarbonization of supply chains, industrial processes and products</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Decarbonization of supply chains, industrial processes and products</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Supply@3x.png" alt="Supply" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              Supply chains are the key component of scope 3 emissions. We look for startups bringing higher transpararency on impacts across the supply chain, enabling a better management of supply networks, or promoting green supply marketplaces. 
                            </p>
                            <p className="af-class-paragraph-large mt-4">                   
                              In parallel, we back B2B startups across a wide range of industries from traditional manufacturing to agriculture, which develop innovative hardware or digital solutions tackling issues such as
                            </p>
                            <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>processes efficiency</li>                              
                              <li>higher efficiency production components and techniques</li>
                              <li>environmental friendly material or component supplies</li>
                            </ul>
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen2: false})}
                          onClose={() => this.setState({ noneOpen2: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen2 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Emissions management</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Emissions management</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Emissions@3x.png" alt="Emissions" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              We invest in startups creating technology to improve the measurement, management, storage and removal of bad emissions.
                            </p>
                            {/* <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>coming up with relevant impact data and measurement frameworks</li>                              
                              <li>empowering public and private companies to take impact-based decisions</li>
                              <li>raising awareness and assisting consumers through their journey</li>
                            </ul> */}
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen2: false})}
                          onClose={() => this.setState({ noneOpen2: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen2 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Resources conservation</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Resources conservation</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Resources@3x.png" alt="Resources" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              We support startups leveraging technology to reduce waste and the resource intensity of economic and industrial activities. This includes innovative packaging, waste collection systems and networks, as well as recycling, reconditioning or conversion solutions.
                            </p>
                            {/* <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>coming up with relevant impact data and measurement frameworks</li>                              
                              <li>empowering public and private companies to take impact-based decisions</li>
                              <li>raising awareness and assisting consumers through their journey</li>
                            </ul> */}
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen2: false})}
                          onClose={() => this.setState({ noneOpen2: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen2 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Energy efficiency</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Energy efficiency</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Energy@3x.png" alt="Energy" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              We invest in startups coming up with solutions that contribute to energy efficiency and conservation across residential, cities infrastructure and industrial applications such as
                              home new generation batteries, solar energy, or vertical farming. We are further interested in peak consumption solutions and energy supply network management solutions. 
                            </p>
                        </div> 
                        </Collapsible>
                        </div>
                        </div>
                      </div>
                    </div>
                    <p className="af-class-paragraph-large mt-20" data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>                   
                      We back disruptive and innovative technologies which can lower the impact of our existing industries and infrastructure. Our future lies with startups which can enable the transformation of corporates, cities and supply networks towards higher sustainability.
                    </p>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToSocialTechSectionProps} />
              </div>
              <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="relative min-w-[100vw] h-[2px]">
                    <div className="af-class-fill-block bg-leapsolvethird-normal" />
                </div>
              <div id="socialimpacttech" className="af-class-section af-class-wf-section min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container content-center items-center place-content-center">
                  <div>
                    <div className="af-class-_2-column-grid af-class-larger-right-column self-center place-self-center">
                      <div>
                        <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="af-class-dash---130px af-class-mg-bottom-32px">
                          <div className="af-class-fill-block af-class-bg-primary-1" />
                        </div>
                        <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleonwhite">Social Impact Tech</h2>
                        <img data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} src="images/All_Social@3x.png" alt="Social" width={200} className="inline mt-[3vh]" />      
                      </div>
                      <div className="af-class-div-block-2 af-class-color-neutral-800 content-center">
                      <div className="flex flex-col gap-y-6">
                        <div
                        >                        
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen3: false})}
                          onClose={() => this.setState({ noneOpen3: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen3 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Circular economy enablers</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Circular economy enablers</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Circular@3x.png" alt="Circular" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              We support startups developing breakthrough solutions promoting the circular economy and business sustainability, or bringing products and technologies (e.g. material science, robotics, agri-tech, advanced traceability technologies, etc...) enabling the development of a circular economy in a particular industry.
                            </p>
                            {/* <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>coming up with relevant impact data and measurement frameworks</li>                              
                              <li>empowering public and private companies to take impact-based decisions</li>
                              <li>raising awareness and assisting consumers through their journey</li>
                            </ul> */}
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen3: false})}
                          onClose={() => this.setState({ noneOpen3: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen3 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Responsible consumption</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Responsible consumption</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Consumption@3x.png" alt="Consumption" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              We invest in startups leveraging technology to lower the impact of humans daily consumption, for instance through
                            </p>
                            <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>sustainable consumer products</li>                              
                              <li>refurbished products collection to sale chains</li>
                              <li>alternative food</li>
                              <li>shared services</li>
                            </ul>
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen3: false})}
                          onClose={() => this.setState({ noneOpen3: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen3 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Diversity, education and inclusion</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Diversity, education and inclusion</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Financial@3x.png" alt="Financial" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              We invest in mission-driven minority or women owned startups leveraging technology, or in startups which support minorities growth by promoting financial inclusion, business creation, access to quality education, increase civic duty access.
                            </p>
                            {/* <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>coming up with relevant impact data and measurement frameworks</li>                              
                              <li>empowering public and private companies to take impact-based decisions</li>
                              <li>raising awareness and assisting consumers through their journey</li>
                            </ul> */}
                          </div> 
                        </Collapsible>
                        </div>
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen3: false})}
                          onClose={() => this.setState({ noneOpen3: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen3 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Information access, technology access and data privacy</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Information access, technology access and data privacy</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Access@3x.png" alt="Access" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              Social value is unlocked by providing access to transparent and unbiased information to and across all populations, by addressing asymmetry of information, and by understanding our needs and behaviors while respecting our privacy. We back startups bringing solutions to these challenges.
                            </p>
                            {/* <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>coming up with relevant impact data and measurement frameworks</li>                              
                              <li>empowering public and private companies to take impact-based decisions</li>
                              <li>raising awareness and assisting consumers through their journey</li>
                            </ul> */}
                          </div> 
                        </Collapsible>
                        </div>                      
                        <div
                        >
                        <Collapsible 
                          containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                          className="af-class-paragraph-large" 
                          onOpening={() => this.setState({ noneOpen3: false})}
                          onClose={() => this.setState({ noneOpen3: true })}
                          transitionTime={300}
                          contentContainerTagName="div"  
                          trigger={<><i className={"fa-solid fa-circle-arrow-right" + (this.state.noneOpen3 ? " animate-xbounce":"")}></i><div className='ml-4 inline'>Healthcare access</div></>} 
                          triggerWhenOpen={<><i className="fa-solid fa-circle-arrow-down"></i><div className='ml-4 inline'>Healthcare access</div></>}
                          triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                          triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                        >                        
                          <div>   
                            <img src="images/Wellness@3x.png" alt="Wellness" width={100} className="float-left mr-6" />      
                            <p className="af-class-paragraph-large mt-4">                   
                              We back startups comming up with technology to reduce healthcare costs, improve access to quality care, solve ailments with no therapeutic solutions.
                            </p>
                            {/* <ul className="mt-4 list-outside text-left overflow-y-auto af-class-paragraph-large af-class-ul-leaf mb-0">
                              <li>coming up with relevant impact data and measurement frameworks</li>                              
                              <li>empowering public and private companies to take impact-based decisions</li>
                              <li>raising awareness and assisting consumers through their journey</li>
                            </ul> */}
                          </div> 
                        </Collapsible>
                        </div>
                        </div>
                      </div>
                    </div>
                    <p className="af-class-paragraph-large mt-20" data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>                   
                      Social impact is usually discarded when compared with carbon-compensation or mobility initiatives. Yet, the adoption of changes in our life-styles will only happen if social value is taken into account and that we - citizens and consumers - can witness positive social impacts. We invest into startups engaged on this path which fit into described missions.
                    </p>
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToHowSectionProps} />
              </div>
              <div data-w-id="9154ae7b-ed5f-a495-270d-69a8eef70c82" className="relative min-w-[100vw] h-[2px]">
                    <div className="af-class-fill-block bg-leapsolvethird-normal" />
                </div>
              <div id="how" className="af-class-section af-class-wf-section min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container content-center items-center place-content-center">
                  <div>
                    <div className="af-class-_2-column-grid af-class-larger-left-column">
                      <div id="w-node-_706af8c8-eaf5-a80a-b225-8c32b67cdc09-c6937890" className="af-class-inner-container-80 af-class-_100-in-mobile">
                        <div className="af-class-card af-class-sticky-card">
                          <div className="af-class-dash---130px af-class-mg-bottom-32px">
                            <div className="af-class-fill-block af-class-bg-primary-1" />
                          </div>
                          <h2 data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0d" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 text-leapsolvethird-normal">
                            How can we help you?
                          </h2>
                          <p data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" className="af-class-paragraph-large mt-20" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>
                          Whether an investor or an entrepreneur, we will assist you in defining your <span className="af-class-text-highlight">impact</span>&nbsp;
                          {/* <HashLink smooth data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342421" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} to="/impact" aria-current="page" className="af-class-button-primary af-class-medium w-inline-block w--current text-leapsolvethird-normal hover:text-black align-middle mb-1">
                            <div>about impact<span className="af-class-button-arrow"></span></div>
                          </HashLink>                           */}
                          , measuring it and, most importantly, building the internal infrastructure required to collect the necessary benchmarking data seamlessly within your organization.                          
                          </p>
                          <div className="group">
                          <p data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" className="af-class-paragraph-large" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>
                          Our greatest value-add however is in our contribution to your <span className="af-class-text-highlight animate-custompulse group-hover:animate-none">operational strategy</span>.
                          </p>
                          <p data-w-id="706af8c8-eaf5-a80a-b225-8c32b67cdc0f" className="hidden group-hover:contents duration-500 af-class-paragraph-large" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>
                          <span className="af-class-text-highlight">Operational strategy?</span> Anyone with an ounce of creativity can come up with a vision or a high-level strategy deck. Only a fraction of entrepreneurs know how to develop a resource-intelligent, contextualized roadmap to get from A to B. We - along with our team of industry-specific experts - will partner with you to help you answer the key questions that will drive business success: What are your target endpoints? What are the steps? What obstacles or challenges can we anticipate and troubleshoot today? How can we remain nimble in the face of the challenge?
                          </p>
                          </div>
                          {/* <HashLink smooth data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342421" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} to="/team" aria-current="page" className="af-class-button-primary w-inline-block w--current">
                          <div>Meet us<span className="af-class-button-arrow"></span></div>
                          <div className="af-class-underline-wrap">
                            <div className="af-class-button-underline" />
                          </div>
                        </HashLink> */}
                        </div>
                      </div>
                      <div>
                        <div className="af-class-card af-class-invested-industries af-class-first pb-4">
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="div"  
                            trigger={<><i className="animate-custompulse inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-pen-to-square flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Strategy</div></>} 
                            triggerWhenOpen={<><i className="inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-pen-to-square flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Strategy</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <ul className="af-class-paragraph-large mt-4 list-disc list-outside text-left overflow-y-auto mb-2">
                              <li>Acquisition of ecosystem intelligence</li>
                              <li>Joint design of product and business strategy</li>
                              <li>Markets and target clients segmentation</li>
                              <li>Partnership strategy</li>
                              <li>Milestones and "pivots" planning</li>
                            </ul>
                          </Collapsible>
                        </div>
                        <div className="af-class-card af-class-invested-industries pb-4">
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="div"  
                            trigger={<><i className="animate-custompulse inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-sliders flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Business Development &amp; Growth Management</div></>} 
                            triggerWhenOpen={<><i className="inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-sliders flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Business Development &amp; Growth Management</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <ul className="af-class-paragraph-large mt-4 list-disc list-outside text-left overflow-y-auto mb-2">
                              <li>Growth sustainability and early revenues generation</li>
                              <li>Brand construction and awareness</li>
                              <li>Design of pricing models</li>
                              <li>Structuring of sales</li>
                              <li>Construction of distribution channels</li>
                              <li>Striking a balance in between product &nbsp; tech depth vs. time-to-market</li>
                            </ul>
                          </Collapsible>
                        </div>
                        <div className="af-class-card af-class-invested-industries pb-4">
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="div"  
                            trigger={<><i className="animate-custompulse inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-toolbox flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Product, Engineering &amp; Operations</div></>} 
                            triggerWhenOpen={<><i className="inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-toolbox flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Product, Engineering &amp; Operations</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <ul className="af-class-paragraph-large mt-4 list-disc list-outside text-left overflow-y-auto mb-2">
                              <li>Product management &amp; engineering structuring</li>
                              <li>Persona-based, rapid idea to market execution</li>
                              <li>Balancing product features vs. accessibility</li>
                              <li>Design of a scalable technological platform by block</li>
                              <li>Productization of internal toolkits</li>
                              <li>Infrastructure automation, CI/CI/DevOps</li>
                            </ul>
                          </Collapsible>
                        </div>
                        <div className="af-class-card af-class-invested-industries af-class-last">
                          <Collapsible 
                            containerElementProps={{ 'data-w-id':"3eb5ca53-81ff-7c0d-f71c-03856ef027cb"}} 
                            className="" 
                            transitionTime={300}
                            contentContainerTagName="div"  
                            trigger={<><i className="animate-custompulse inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-scale-balanced flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Financing &amp; Expansion</div></>} 
                            triggerWhenOpen={<><i className="inline-flex text-white bg-leapsolvethird-normal rounded-full fa-solid fa-scale-balanced flex self-start align-start items-center justify-center w-10 h-10"></i><div className='ml-4 inline'>Financing &amp; Expansion</div></>}
                            triggerClassName="af-class-paragraph-xlarge af-class-text-highlight" 
                            triggerOpenedClassName="af-class-paragraph-xlarge af-class-text-highlight"
                          >                        
                            <ul className="af-class-paragraph-large mt-4 list-disc list-outside text-left overflow-y-auto">
                              <li>Best financing option design and timing</li>
                              <li>Fund raising assistance</li>
                              <li>Acceleration and gap-filling M&amp;A</li>
                              <li>International expansion</li>
                              <li>Raising interest of and negotiating with strategic parties</li>
                            </ul>
                          </Collapsible>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ScrollTopButton {...buttonScrollToTopSectionProps} />
              </div>
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default StrategyView

/* eslint-enable */