import React from "react";
import PropTypes from "prop-types";

// components
import CardInfoIconLeftFlip from "./CardInfoIconLeftFlip.js";

export default function MultipleFlippingCardsWrapper({ nbCols, image, cards }) {
  return (
    // <div className={"h-full grid grid-cols-3 grid-rows-3 gap-x-3 gap-y-1"}>
    //   {cards.map((prop, key) => (
    //     // <div key={key} className="flex py-4 px-6 relative w-full h-full lg:w-1/2">
    //     <div key={key} className="col-span-1 row-span-1 flex py-2 px-2 items-stretch">
    //       <CardInfoIconLeftFlip {...prop} />
    //     </div>
    //   ))}
    // </div>
    <>
      <div
        className="relative align-middle bg-transparent text-center flex h-full"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <div className="container mx-auto px-4 py-8 flex h-full">
          <div className="flex flex-wrap h-full">
            {cards.map((prop, key) => (
              // <div key={key} className="flex py-4 px-6 relative w-full h-full lg:w-1/2">
              <div key={key} className={"flex py-2 px-3.5 lg:h-[18vh] w-1/1 lg:w-1/" + nbCols}>
                <CardInfoIconLeftFlip {...prop} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

MultipleFlippingCardsWrapper.defaultProps = {
  cards: [],
  color: "leapsolvethird",
  nbCols: 3
};
MultipleFlippingCardsWrapper.propTypes = {
  image: PropTypes.string,
  // array of props to pass to the CardInfoIconLeft component
  cards: PropTypes.arrayOf(PropTypes.object),
  nbCols: PropTypes.number
};
