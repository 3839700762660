import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import AOS from 'aos';

// From appfairy integration of webflow project
import './styles'
import './scripts'
import Index from './pages/Index';
import Connect from './pages/Connect';
//import Manifesto from './pages/Manifesto';
import Strategy from './pages/Strategy';
import Impact from './pages/Impact';
import Portfolio from './pages/Portfolio';
import News from './pages/News';
import AboutUs from './pages/AboutUs';
//import Team from './pages/Team';
import NotFound from './pages/NotFound';

function useSegmentPageTracking() {
  const location = useLocation();
  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
};

function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

  // //hubspot integration
  // useEffect(() => {
  //   var _hsq = window._hsq = window._hsq || [];
  //   _hsq.push(['setPath', location.pathname]);
  //   _hsq.push(['trackPageView']);
  // }, [location.pathname]); // triggered on route change

  //Segment hook  
  useSegmentPageTracking()

  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/index" element={<Index />} />
        <Route path="/index.html" element={<Index />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/connect.html" element={<Connect />} />
        {/* <Route path="/manifesto" element={<Manifesto />} />
        <Route path="/manifesto.html" element={<Manifesto />} /> */}
        <Route path="/strategy" element={<Strategy />} />
        <Route path="/strategy.html" element={<Strategy />} />
        <Route path="/impact" element={<Impact />} />
        <Route path="/impact.html" element={<Impact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio.html" element={<Portfolio />} />
        <Route path="/news" element={<News />} />
        <Route path="/news.html" element={<News />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/aboutus.html" element={<AboutUs />} />
        {/* <Route path="/team" element={<Team />} />
        <Route path="/team.html" element={<Team />} /> */}
        <Route path="*" element={<NotFound />} />
        {/* <Route exact path="/" element={<Home />} /> */}
        {/* <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-post" element={<BlogPost />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/help" element={<Help />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<PageNotFound />} /> */}
      </Routes>    
    </>
  );
}

export default App;
