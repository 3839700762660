import React from "react";
// import ContactFormView from '../views/ContactFormView';
import { Widget } from '@typeform/embed-react';

class TypeFormConnect extends React.Component {
    state = {}
    
    render() {
        return (
            <Widget 
                id="JG2cfh2E" 
                container="div"
                hideHeaders={true}                
                hideFooter={false} 
                opacity={0}
                //autoResize={true}
                style={{ width: '100%', height:'60vh' }} 
                className="get-in-touch"
                //iframeProps={{ style: "overflow:hidden", scrolling:"no" }}
            />
            // <ContactFormView>
            //     <contactformname /> {/* af-sock attributed with value name */}
            //     <contactformemail /> {/* af-sock attributed with value submit */}
            //     <contactformphone /> {/* af-sock attributed with value submit */}
            //     <contactformcompany /> {/* af-sock attributed with value submit */}
            //     <contactformcontent /> {/* af-sock attributed with value submit */}
            //     <contactformsubmit /> {/* af-sock attributed with value submit */}
            // </ContactFormView>
        )
    }
}

export default TypeFormConnect;