import React from 'react'

export default function SiteFooter() {
  return (
    <span>
      <style dangerouslySetInnerHTML={{ __html: `
        @import url(/css/normalize.css);
        @import url(/css/webflow.css);
        @import url(/css/leapsolve.webflow.css);
      ` }} />
      <span className="af-view af-class-page-wrapper">
        <footer className="af-class-footer bg-transparent">
          <div className="af-class-container-default">
            {/* <div className="af-class-footer-grid af-class-top">
              <a id="w-node-_1356e90d-e3a3-2953-9ae6-a897ae2f46c8-ae2f46c5" data-w-id="1356e90d-e3a3-2953-9ae6-a897ae2f46c8" href="index.html" aria-current="page" className="af-class-footer-logo-container w-inline-block w--current"><img src="images/logo-investor-x-template.svg" alt className="af-class-footer-logo" /></a>
              <div id="w-node-_0c444a13-15fd-73a6-ead6-0d9e9283e0ea-ae2f46c5">
                <p data-w-id="690c7719-9459-4a23-7bb4-c8a5b2ef8628" className="af-class-mg-bottom-0px">Lorem ipsum dolor sit amet consectetur adipiscing elit cras elitolil me magna blandit sed egestas augue etiam massa nec.</p>
              </div>
            </div> */}
            {/* <div className="w-layout-grid af-class-footer-grid af-class-middle">
              <div id="w-node-_3df15fd2-dbd1-caa9-c7ba-3cb6881efa14-ae2f46c5" data-w-id="3df15fd2-dbd1-caa9-c7ba-3cb6881efa14" className="af-class-footer-column">
                <div className="af-class-footer-title af-class-mg-bottom-40px">Pages</div>
                <div className="af-class-footer-nav-wrapper">
                  <ul className="af-class-footer-nav">
                    <li className="af-class-footer-nav-item">
                      <a href="index.html" aria-current="page" className="af-class-footer-link w--current">Home</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="about.html" className="af-class-footer-link">About</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="blog.html" className="af-class-footer-link">Blog</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="https://investortemplate.webflow.io/blog-posts/how-to-raise-capital-5-fundraising-strategies-for-your-startup" className="af-class-footer-link">Blog Post</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="connect.html" className="af-class-footer-link">Contact</a>
                    </li>
                    <li className="af-class-footer-nav-item af-class-last">
                      <a href="portfolio.html" className="af-class-footer-link">Portfolio</a>
                    </li>
                  </ul>
                  <ul className="af-class-footer-nav af-class-last">
                    <li className="af-class-footer-nav-item">
                      <a href="meet-us.html" className="af-class-footer-link">Investors</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="https://investortemplate.webflow.io/investors/john-carter" className="af-class-footer-link">Investor Single</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="shop.html" className="af-class-footer-link">Shop</a>
                    </li>
                    <li className="af-class-footer-nav-item af-class-last">
                      <a href="https://investortemplate.webflow.io/product/investor-x-cup" className="af-class-footer-link">Shop Single</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="w-node-_8c3a376d-52fe-44a5-d64a-f60cad0c1eef-ae2f46c5" data-w-id="8c3a376d-52fe-44a5-d64a-f60cad0c1eef" className="af-class-footer-column">
                <div className="af-class-footer-title af-class-mg-bottom-40px">Utility Pages</div>
                <div className="af-class-footer-nav-wrapper">
                  <ul className="af-class-footer-nav af-class-last">
                    <li className="af-class-footer-nav-item">
                      <a href="utility-pages/start-here.html" className="af-class-footer-link">Start Here</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="utility-pages/style-guide.html" className="af-class-footer-link">Style Guide</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="https://investortemplate.webflow.io/404" className="af-class-footer-link">404 Not Found</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="https://investortemplate.webflow.io/401" className="af-class-footer-link">Password Protected</a>
                    </li>
                    <li className="af-class-footer-nav-item">
                      <a href="utility-pages/licenses.html" className="af-class-footer-link">Licenses</a>
                    </li>
                    <li className="af-class-footer-nav-item af-class-last">
                      <a href="utility-pages/changelog.html" className="af-class-footer-link">Changelog</a>
                    </li>
                  </ul>
                </div>
              </div> 
              <div id="w-node-_80d2d1bd-7d0d-d69b-674f-b3dfec163fbe-ae2f46c5" data-w-id="80d2d1bd-7d0d-d69b-674f-b3dfec163fbe" className="af-class-footer-column">
                <div className="af-class-mg-bottom-0px w-form">
                  <div className="af-class-footer-title af-class-mg-bottom-40px">Subscribe to our newsletter</div>
                  <p>Lorem ipsum dolor sit amet consectetur adipiscin elit aliquam mauris sed ma</p>
                  <form id="wf-form-Footer-Newsletter-Form" name="wf-form-Footer-Newsletter-Form" data-name="Footer Newsletter Form" method="get" className="af-class-footer-newsletter-form"><input type="email" className="af-class-input af-class-white af-class-mg-bottom-32px w-input" maxLength={256} name="email" data-name="Email" placeholder="Email address" id="email" required /><input type="submit" defaultValue="Subscribe" data-wait="Please wait" className="af-class-footer-submit-button w-button" /></form>
                  <div className="af-class-success-message w-form-done">
                    <div>Thanks for subscribing to our newsletter</div>
                  </div>
                  <div className="af-class-error-message w-form-fail">
                    <div>Oops! Something went wrong.</div>
                  </div>
                </div>
              </div>
            </div>*/}
            <div className="af-class-footer-bottom-content flex-col lg:flex-row gap-4 lg:gap-0">
              <div className="af-class-div-block-3">
                <div data-w-id="22f1d813-2ba8-69a6-baca-3d41fc54634a" className="af-class-text-block text-leapsolve-second-normal">Copyright © 2024, LeapSolve Capital 
                </div>
                {/* <img src="images/logo-flat-color.png" loading="eager" width={180} alt="LeapSolve Capital" className="af-class-image-3" /> */}
              </div>
              {/* <div data-w-id="03ba0624-24bc-133e-a511-53e83ad2f018" className="af-class-social-links-wrap af-class-footer">
                <a href="https://impactmanagementproject.com/" target="_blank" rel="noreferrer" className="af-class-link-block w-inline-block"><img src="images/impact-management-project.png" loading="eager" width={80} alt="Impact Management Project" /></a>
                <link rel="prefetch" href="https://impactmanagementproject.com/" />
                <a href="https://sdgs.un.org/goals" target="_blank" rel="noreferrer" className="af-class-link-block w-inline-block"><img src="images/sustainable-development-goals.png" loading="eager" width={200} alt="Sustainable Development Goals" className="af-class-image-4" /></a>
                <link rel="prefetch" href="https://sdgs.un.org/goals" />
                <a href="https://twitter.com/" target="_blank" rel="noreferrer" className="af-class-social-link"></a>
                <link rel="prefetch" href="https://twitter.com/" />
                <a href="https://www.linkedin.com/company/leapsolve" target="_blank" rel="noreferrer" className="af-class-social-link"></a>
                <link rel="prefetch" href="https://www.linkedin.com/company/leapsolve" />
              </div> */}
            </div>
          </div>
        </footer>
      </span>
    </span>
  )

}
