import React from "react";
import PropTypes from "prop-types";

// components
//import Heading2Badge from "../../../../components/Headings/Heading2Badge.js";
import CardProfileFull from "./CardProfileFull";

export default function MultipleProfilesWrapper({ nbCols, image, cards }) {
  return (
    <>
      <div
        className="relative align-middle bg-transparent text-center mt-[60px] w-full"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        {/* <div className="container px-4 py-8"> */}
          {/* {heading2badge && <Heading2Badge {...heading2badge} />} */}
          <div className="mt-12 flex flex-wrap flex-col lg:flex-row -mx-4 gap-x-2 justify-center items-center">
            {cards.map((prop, key) => (
              <div key={key} className="flex py-4 px-6 relative w-full h-full md:w-1/2 xl:w-1/3 max-w-[400px]">
                <CardProfileFull {...prop} />
              </div>
            ))}
          </div>
        </div>
      {/* </div> */}
    </>
  );
}

MultipleProfilesWrapper.defaultProps = {
  cards: [],
  color: "blueGray",
  heading2badge: null,
};
MultipleProfilesWrapper.propTypes = {
  // prop to pass to the Heading2Badge component
  heading2badge: PropTypes.object,
  image: PropTypes.string,
  // array of props to pass to the CardInfoIconLeft component
  cards: PropTypes.arrayOf(PropTypes.object),
};
