import React from 'react'
import PropTypes from "prop-types";

export default function SiteHeader({displayHome}) {
  return (
    <span className="">
      <style dangerouslySetInnerHTML={{ __html: `
        @import url(/css/normalize.css);
        @import url(/css/webflow.css);
        @import url(/css/leapsolve.webflow.css);
      ` }} />
      <span className="af-view af-class-page-wrapper">
        <div data-collapse="medium" data-animation="default" data-duration={400} data-w-id="04824156-eced-c654-ab98-88caa897ff70" data-easing="ease" data-easing2="ease" role="banner" className="af-class-header w-nav fixed animate-xheader">
          <div className="af-class-container-default w-container">
            <div className="af-class-header-wrapper">
              <div className="af-class-split-content af-class-header-right">
                <a href="index" aria-current="page" className="af-class-header-link w-nav-brand w--current">
                  <img src="images/leapsolve_transparent.png" sizes="(max-width: 100px) 100vw, 50px" srcSet="images/leapsolve_transparent.png 500w, images/leapsolve_transparent.png 1241w" alt="LeapSolve Capital" className="af-class-header-logo" />
                </a>
             </div>
              <div className="af-class-split-content af-class-header-left">
                {/* <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <ul className="af-class-header-navigation max-w-[60vw] text-[1.3vw] xl:text-base">
                    {(displayHome === true) && (<li className="af-class-nav-item-wrapper">
                      <a href="index" aria-current="page" className="af-class-nav-link w--current">Home</a>
                    </li>)}
                    <li className="af-class-nav-item-wrapper">
                      <a href="strategy" aria-current="page" className="af-class-nav-link w--current">Strategy</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="portfolio" aria-current="page" className="af-class-nav-link w--current">Portfolio</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="impact" aria-current="page" className="af-class-nav-link w--current">Impact</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="news" aria-current="page" className="af-class-nav-link w--current">News</a>
                    </li>
                    <li className="af-class-nav-item-wrapper">
                      <a href="aboutus" aria-current="page" className="af-class-nav-link w--current">About Us</a>
                    </li>
                    <li className="af-class-nav-item-wrapper animate-custompulse text-leapsolvesecond-normal">
                      <a href="connect" className="af-class-nav-link text-leapsolvesecond-normal font-bold">Connect</a>
                    </li>
                  </ul>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="af-class-menu-button-icon-main-wrap">
                    <div className="af-class-menu-button-icon-wrap">
                      <div className="af-class-menu-button-icon-line af-class-top" />
                      <div className="af-class-menu-button-icon-line af-class-middle" />
                      <div className="af-class-menu-button-icon-line af-class-bottom" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  )

}

SiteHeader.defaultProps = {
  displayHome: true
};

SiteHeader.propTypes = {
  displayHome: PropTypes.bool
};