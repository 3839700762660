/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { HashLink } from 'react-router-hash-link';
// components

// //Examples props declaration
// const buttonConnectProps = {
//   image:"contact-white.png",
//   displayText:"Get in touch",
//   link: "connect.html",
// }
// const buttonPortfolioProps = {
//   image:"ideas-white3x.png",
//   displayText:"Investment Portfolio",
//   link: "index.html",
// }
// //Examples usage
// <ButtonFullEffects {...buttonConnectProps} />
// <ButtonFullEffects {...buttonPortfolioProps} />

export default function ButtonSimpleEffects({
  displayText,
  link,
  onDark,
  appearOnScroll,
  flexText,
  displayArrow = true,
}) {

  if (onDark) {
    return (
      <span>
      <style dangerouslySetInnerHTML={{ __html: `
        @import url(/css/normalize.css);
        @import url(/css/webflow.css);
        @import url(/css/leapsolve.webflow.css);
      ` }} />
      <span className="af-view font-family-main bg-transparent hover:bg-transparent">
        <HashLink smooth data-w-id={appearOnScroll ? "f4e69ca9-4bf5-636d-0b9c-3702a8342421" : ""} style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 0) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} to={link} aria-current="page" className="af-class-button-primary af-class-buttonsimpleeffects text-leapsolvesecond-normal group-hover:text-white w-inline-block w--current">
          <div className={classnames(
            "bg-transparent hover:bg-transparent",
            {
              "text-sm lg:text-lg xl:text-xl": flexText,
              "text-lg": !flexText
            }
          )}>{displayText}{(displayArrow === true) && <span className="af-class-button-arrow af-class-buttonsimpleeffects"></span>}</div>
          <div className="af-class-underline-wrap">
            <div className="af-class-button-underline bg-leapsolvesecond-normal group-hover:bg-transparent" />
          </div>
        </HashLink>
      </span>
    </span>
    );
  } else {
    return (
      <span>
      <style dangerouslySetInnerHTML={{ __html: `
        @import url(/css/normalize.css);
        @import url(/css/webflow.css);
        @import url(/css/leapsolve.webflow.css);
      ` }} />
      <span className="af-view font-family-main bg-transparent hover:bg-transparent">
        <HashLink smooth data-w-id={appearOnScroll ? "f4e69ca9-4bf5-636d-0b9c-3702a8342421" : ""} style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 0) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 1}} to={link} aria-current="page" className="af-class-button-primary af-class-buttonsimpleeffects text-leapsolvethird-normal group-hover:text-black w-inline-block w--current">
          <div className={classnames(
            "bg-transparent hover:bg-transparent",
            {
              "text-sm lg:text-lg xl:text-xl": flexText,
              "text-lg": !flexText
            }
          )}>{displayText}<span className="af-class-button-arrow af-class-buttonsimpleeffects"></span></div>
          <div className="af-class-underline-wrap">
            <div className="af-class-button-underline bg-leapsolvethird-normal group-hover:bg-black" />
          </div>
        </HashLink>
      </span>
    </span>
    );
  }
}
ButtonSimpleEffects.defaultProps = {
  onDark: false,
  appearOnScroll: true,
  flexText: false
};

ButtonSimpleEffects.propTypes = {
  displayText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  onDark: PropTypes.bool,
  appearOnScroll: PropTypes.bool,
  flexText: PropTypes.bool
};
