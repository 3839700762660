import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { createScope, map, transformProxies } from './helpers'
import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton';
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import NutshellContent from '../../components/NutshellContent'
// import TypeAnimation from 'react-type-animation'
import ReactTextTransition, { presets } from "react-text-transition";
// https://www.npmjs.com/package/react-text-transition
import Collapsible from 'react-collapsible';

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

const rollingTexts = [
  'Growing together to fight climate change and reinvent our world',
  'Transforming existing industries and infrastructure through sustainable technologies and supply chain management',
  'Generating positive social impact through circular economy, inclusion, technology and information access'
];

let Controller

class IndexView extends React.Component {

  state = {
    textIndex: 0,
    collapsiblesStates: [false, false, false, false, false],
    noneOpen: true,
  };

  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Index')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code === 'MODULE_NOT_FOUND') {
        Controller = IndexView

        return Controller
      }

      throw e
    }
  }

  // toggleOpen(collapsibleIndex) {
  //   let {collapsiblesStates, noneOpen} = this.state;
  //   let newState = !collapsiblesStates[collapsibleIndex];
  //   collapsiblesStates[collapsibleIndex] = newState;
  //   if (newState===true) { noneOpen = false; } else {
  //     noneOpen = collapsiblesStates.reduce((prev, cur) => !prev && !cur, false);
  //   }
  //   this.setState({collapsiblesStates:collapsiblesStates, noneOpen: noneOpen});
  // }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))

    setInterval(() => {
      this.setState({
        textIndex: this.state.textIndex + 1,
      });
    }, 3000);    
  }

  render() {
    // const proxies = IndexView.Controller !== IndexView ? transformProxies(this.props.children) : {
      
    // }

    // const buttonConnectProps = {
    //   image:"contact-white.png",
    //   displayText:"Get in touch",
    //   link: "/connect",
    // }
    // const buttonManifestoProps = {
    //   image:"ideas-white3x.png",
    //   displayText:"Read our Manifesto",
    //   link: "/aboutus#manifesto",
    // }

    // const buttonScrollToMissionSectionProps = {
    //   link:"index#mission",
    //   webflowanimate:false,
    // }

    // const buttonScrollToNutshellSectionProps = {
    //   link:"index#leapsolve101",
    // }

    // const buttonScrollToOpportunitiesSectionProps = {
    //   link:"index#opportunities",
    // }    
    // const buttonScrollToTopSectionProps = {
    //   link:"index#top",
    // }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/leapsolve.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader displayHome={false} />
              <div className="af-class-section af-class-home-hero af-class-wf-section animate-xappear">
                {/* <div className="af-class-container-2 w-container"><img src="images/dune-illustration-bg-black3x.png" loading="lazy" sizes="50vw" srcSet="images/dune-illustration-bg-black3x-p-500.png 500w, images/dune-illustration-bg-black3x-p-800.png 800w, images/dune-illustration-bg-black3x-p-1080.png 1080w, images/dune-illustration-bg-black3x.png 1567w" alt="LeapSolve Capital Illustration" className="af-class-image-2" /></div> */}
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-mg-bottom-large-72px">
                      <div className="af-class-title-wrapper af-class-mg-bottom-24px max-w-[50vw]">
                        <h2 className="af-class-display-1 af-class-color-white af-class-home-hero-title animate-xappearfadein text-3xl">
                          Beyond providing capital, we help mission-driven founders leap over the most pressing challenges facing the world today, from idea to execution.                           
                          {/* <span className="opacity-50">We partner with </span><span className="italic animate-custompulse2">groundbreaking</span><br /> 
                          <span className="italic animate-custompulse2">technology founders</span>
                          <br /><span className="opacity-50">who foster a </span><span className="italic animate-custompulse2">scalable</span>,<br />
                          <span className="italic animate-custompulse2">immediate</span><span className="opacity-50"> and </span><span className="italic animate-custompulse2">positive impact</span> */}
                        </h2>
                        {/* <h1 className="af-class-display-1 af-class-color-white af-class-home-hero-title animate-xappearfadein">We partner with<br />impact-driven<br />tech founders</h1> */}
                        <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                          <div className="af-class-fill-block af-class-bg-neutral-100 bg-leapsolvesecond-normal opacity-50" />
                        </div>
                      </div>
                      {/* <div className="af-class-paragraph-large af-class-home-hero-paragraph h-[80px]"> 
                        <ReactTextTransition
                          text={rollingTexts[this.state.textIndex % rollingTexts.length]}
                          springConfig={{ stiffness: 50, damping: 20 }}
                          noOverflow
                        />
                      </div> */}
                    </div>
                    {/* <div className="af-class-_2-buttons-wrap max-w-[60vw] flex justify-between items-center flex-col lg:flex-row gap-y-3 animate-xappearfadein">
                      <ButtonFullEffects {...buttonConnectProps} />
                      <ButtonFullEffects {...buttonManifestoProps} />
                      <div>
                        <a href="https://impactassets.org/IA-50" target="_blank" rel="noreferrer" aria-current="page" className="af-class-header-link w-nav-brand w--current"><img src="images/ImpactAssets_Green.png" width={140} alt="Impact Assets Top 50" /></a>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* <ScrollSectionButton {...buttonScrollToMissionSectionProps} /> */}
              </div>
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default IndexView