/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import ReactCardFlip from 'react-card-flip';
import ButtonSimpleEffects from "../webflow/ButtonSimpleEffects";

export default function CardInfoIconLeftFlip({
  icon,
  color,
  title,
  description,
  link,
  inverted,
  isFixed,
  scale,
  scalePercentage
}) {
  // const [isFlipped, setFlipped] = React.useState(false);
  const [isFlipped, dispatchFlip] = React.useReducer((state, action) => { return !state }, false);
  // const toggle = (e, showFace) => {
  //   e.preventDefault();
  //   setTimeout(function() {
  //     setFlipped(!showFace)
  //   }, 100);
  // }

  let iconColor = {
    light: "bg-blueGray-200 text-blueGray-800",
    dark: "bg-blueGray-800 text-blueGray-100",
    blueGray: "bg-blueGray-500 text-white",
    red: "bg-red-500 text-white",
    orange: "bg-orange-500 text-white",
    amber: "bg-amber-500 text-white",
    emerald: "bg-emerald-500 text-white",
    leapsolvethird: "bg-leapsolvethird-normal text-white",
    transparentondark: "bg-transparent text-white",
    teal: "bg-teal-500 text-white",
    lightBlue: "bg-lightBlue-500 text-white",
    indigo: "bg-indigo-500 text-white",
    purple: "bg-purple-500 text-white",
    pink: "bg-pink-500 text-white",
    "light-inverted": "text-blueGray-200 bg-blueGray-800",
    "dark-inverted": "text-blueGray-800 bg-blueGray-100",
    "blueGray-inverted": "text-blueGray-500 bg-white",
    "red-inverted": "text-red-500 bg-white",
    "orange-inverted": "text-orange-500 bg-white",
    "amber-inverted": "text-amber-500 bg-white",
    "emerald-inverted": "text-emerald-500 bg-white",
    "teal-inverted": "text-teal-500 bg-white",
    "lightBlue-inverted": "text-lightBlue-500 bg-white",
    "indigo-inverted": "text-indigo-500 bg-white",
    "purple-inverted": "text-purple-500 bg-white",
    "pink-inverted": "text-pink-500 bg-white",
  };
  let textColor = {
    light: "text-blueGray-400",
    dark: "text-leapsolvethird-normal",
    blueGray: "text-blueGray-500",
    red: "text-red-500",
    orange: "text-orange-500",
    amber: "text-amber-500",
    emerald: "text-emerald-500",
    leapsolvethird: "text-leapsolvethird-normal",
    transparentondark: "text-leapsolvesecond-normal",
    teal: "text-teal-500",
    lightBlue: "text-lightBlue-500",
    indigo: "text-indigo-500",
    purple: "text-purple-500",
    pink: "text-pink-500",
    "light-inverted": "text-blueGray-800",
    "dark-inverted": "text-white",
    "blueGray-inverted": "text-white",
    "red-inverted": "text-white",
    "orange-inverted": "text-white",
    "amber-inverted": "text-white",
    "emerald-inverted": "text-white",
    "teal-inverted": "text-white",
    "lightBlue-inverted": "text-white",
    "indigo-inverted": "text-white",
    "purple-inverted": "text-white",
    "pink-inverted": "text-white",
  };
  const cardColors = {
    "light-inverted": "bg-blueGray-400 text-blueGray-800",
    "dark-inverted": "bg-blueGray-800 text-white",
    "blueGray-inverted": "bg-blueGray-500 text-white",
    "red-inverted": "bg-red-500 text-white",
    "orange-inverted": "bg-orange-500 text-white",
    "amber-inverted": "bg-amber-500 text-white",
    "emerald-inverted": "bg-emerald-500 text-white",
    "teal-inverted": "bg-teal-500 text-white",
    "lightBlue-inverted": "bg-lightBlue-500 text-white",
    "indigo-inverted": "bg-indigo-500 text-white",
    "purple-inverted": "bg-purple-500 text-white",
    "pink-inverted": "bg-pink-500 text-white",
    light: "bg-white text-blueGray-800",
    blueGray: "bg-white text-blueGray-800",
    red: "bg-white text-blueGray-800",
    orange: "bg-white text-blueGray-800",
    amber: "bg-white text-blueGray-800",
    emerald: "bg-white text-blueGray-800",
    leapsolvethird: "bg-white text-black",
    dark: "bg-leapsolvefirst-masked/25 text-leapsolvethird-normal",
    transparentondark: "bg-transparent text-leapsolvethird-normal",
    teal: "bg-white text-blueGray-800",
    lightBlue: "bg-white text-blueGray-800",
    indigo: "bg-white text-blueGray-800",
    purple: "bg-white text-blueGray-800",
    pink: "bg-white text-blueGray-800",
  };
  if (!isFixed) {
    return (
      <div
        className="h-full w-full flex grow"
        onMouseEnter={() => dispatchFlip("flip")}
        onMouseLeave={() => dispatchFlip("flip")}
        > 
      <ReactCardFlip containerClassName="h-full w-full content-center items-center place-content-center align-middle" isFlipped={isFlipped} flipDirection="horizontal" flipSpeedBackToFront={0.6} flipSpeedFrontToBack={0.6}>
          <div
            className={"h-full group transform-none transform " +
              cardColors[color + (inverted ? "-inverted" : "")] +
              " rounded-lg text-left flex flex-1" +
              // " hover:bg-leapsolvesecond-normal hover:text-black" + 
              " transition-none transition duration-500" +
              (scale ? " hover:scale-" + scalePercentage : "")
            }
            // onMouseEnter={(e) => toggle(e, false)}
            // onMouseEnter={() => dispatchFlip("flip")}
          >
            {/* <div className="flex flex-col p-2">
              <div
                className={"transform " +
                  iconColor[color + (inverted ? "-inverted" : "")] +
                  " shadow-lg rounded-full justify-center items-center text-center p-2 text-xl w-12 h-12"
                  // " shadow-lg rounded-full justify-center items-center text-center p-2 text-xl w-12 h-12" +
                  // " group-hover:text-leapsolvesecond-normal group-hover:bg-black"
                }
              >
                <i className={icon}></i>
              </div>
              <div className="flex-1" />
            </div> */}
            <div className="flex flex-col p-2 items-center align-middle justify-center self-auto w-full">
              <div
                className={"transform " +
                  textColor[color + (inverted ? "-inverted" : "")] +
                  " text-xl font-bold align-middle justify-center text-center"
                  // " text-xl font-bold mt-3 mb-0" +
                  // " group-hover:text-black"
                }
              >
                {title}
              </div>
              {/* <p
                className={"flex-1 transform " +
                  (inverted ? "text-white opacity-75" : "text-black") +
                  " mt-2 mb-4 leading-relaxed"
                  // " mt-2 mb-4 leading-relaxed" +
                  // " group-hover:text-black"
                }
              >
                {description}
              </p>
              {link && link.href && (
              <ButtonSimpleEffects 
                link={link.href}
                displayText={(link.children ? link.children : "More available soon")}
              />
              )} */}
              {/* {link && link.to && (
                <Link
                  {...link}
                  className={"transform " + 
                  textColor[color + (inverted ? "-inverted" : "") +
                  " group-hover:text-black"
                ]}
                />
              )}
              {link && link.to === undefined && (
                <a
                  {...link}
                  className={"transform " +
                  textColor[color + (inverted ? "-inverted" : "") +
                  " group-hover:text-black"
                ]}
                />
              )} */}
            </div>
          </div>

          <div
            className={"h-full group transform-none transform " +
              cardColors[color + (inverted ? "-inverted" : "")] +
              " rounded-lg text-left flex flex-1" +
              " content-center items-center place-content-center align-middle" +
              " bg-leapsolvesecond-normal text-black" + 
              // " hover:bg-leapsolvesecond-normal hover:text-black" + 
              " transition-none transition duration-500" +
              (scale ? " hover:scale-" + scalePercentage : "")
            }
            // onMouseLeave={() => setFlipped(toggle)}
            // onMouseLeave={(e) => toggle(e, true)}
            // onMouseLeave={() => dispatchFlip("flip")}
          >
            {/* <div className="flex flex-col p-2">
              <div
                className={"transform " +
                  iconColor[color + (inverted ? "-inverted" : "")] +
                  " shadow-lg rounded-full justify-center items-center text-center p-2 text-xl w-12 h-12" +
                  " text-leapsolvesecond-normal bg-black"
                  // " group-hover:text-leapsolvesecond-normal group-hover:bg-black"
                }
              >
                <i className={icon}></i>
              </div>
              <div className="flex-1" />
            </div> */}
            <div className="h-full flex flex-col p-2 place-content-center align-middle justify-center items-center text-center content-center">
              {/* <h6
                className={"transform " +
                  textColor[color + (inverted ? "-inverted" : "")] +
                  " text-xl font-bold mt-3 mb-0" +
                  " text-black"
                  // " group-hover:text-black"
                }
              >
                {title}
              </h6> */}
              <div
                className={"transform " +
                  (inverted ? "text-white opacity-75" : "text-white") +
                  " leading-relaxed font-medium text-base lg:text-sm xl:text-base" +
                  " place-content-center align-middle items-center justify-center text-center content-center"
                  // " group-hover:text-black"
                }
              >
                {description}
              </div>
              {link && link.href && (
              <ButtonSimpleEffects 
                link={link.href}
                displayText={(link.children ? link.children : "More available soon")}
              />
              )}
              {/* {link && link.to && (
                <Link
                  {...link}
                  className={"transform " + 
                  textColor[color + (inverted ? "-inverted" : "") +
                  " group-hover:text-black"
                ]}
                />
              )}
              {link && link.to === undefined && (
                <a
                  {...link}
                  className={"transform " +
                  textColor[color + (inverted ? "-inverted" : "") +
                  " group-hover:text-black"
                ]}
                />
              )} */}
            </div>
          </div>
      </ReactCardFlip>
      </div>

    );
  } else {
    return (  
      <div
        className={"group transform " +
          cardColors[color + (inverted ? "-inverted" : "")] +
          " rounded-lg text-left flex flex-1 items-center" +
          // " hover:bg-leapsolvesecond-normal hover:text-black" + 
          " transition duration-500" +
          (scale ? " hover:scale-" + scalePercentage : "")
        }
      >    
        <div className="items-center align-middle justify-center self-auto flex flex-col w-full p-2">
          <div
            className={"w-full align-middle justify-center text-center self-auto transform " +
              textColor[color + (inverted ? "-inverted" : "")] +
              " text-xl font-bold"
              // " text-xl font-bold mt-3 mb-0" +
              // " group-hover:text-black"
            }
          >
            {title}
          </div>
        </div>
      </div>);
  }
}
CardInfoIconLeftFlip.defaultProps = {
  color: "leapsolvethird",
  link: {},
  inverted: false,
  isFixed: false,
  scale: false,
  scalePercentage: 105
};

CardInfoIconLeftFlip.propTypes = {
  // icon name to be added on the <i></i> tag, for example "fa fa-heart"
  icon: PropTypes.string.isRequired,
  // the background of the icon
  color: PropTypes.oneOf([
    "light",
    "dark",
    "blueGray",
    "red",
    "orange",
    "amber",
    "emerald",
    "leapsolvethird",
    "transparentondark",
    "teal",
    "lightBlue",
    "indigo",
    "purple",
    "pink",
  ]),
  // if true, than the color will go as background
  // for the whole card, not only the icon
  inverted: PropTypes.bool,
  // if the background should have a gradient
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // props to pass to the anchor as object
  // if you pass a prop "to" it will be
  // generated as a Link from react-router-dom
  // otherwise as an <a> tag
  link: PropTypes.object,
  isFixed: PropTypes.bool,
  scale: PropTypes.bool,
  scalePercentage: PropTypes.number
};
