/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import PropTypes from "prop-types";
import { HashLink } from 'react-router-hash-link';
// components

// //Examples props declaration
// const buttonScrollToMissionProps = {
//   displayText:"Investment Portfolio",
//   link: "#Mission",
// }
// //Examples usage
// <ScrollSectionButton {...buttonScrollToMissionProps} />

export default function ScrollSectionButton({
  link,
  webflowanimate
}) {
  return (
    <span>
    <style dangerouslySetInnerHTML={{ __html: `
      @import url(/css/normalize.css);
      @import url(/css/webflow.css);
      @import url(/css/leapsolve.webflow.css);
    ` }} />
      {(webflowanimate === true) ? 
      (<span className="af-view">
        <HashLink smooth to={"/"+link} 
          data-w-id="5d772935-4b68-8fc4-5a8d-0f18295a938d" 
          style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} 
          className="af-class-scroll-down-button af-bottom-right"
        > {/* af-class-home-hero w-inline-block */}
          <img src="images/SDG_Wheel_Transparent_WEB.png" 
//            data-w-id="c60ce000-668b-89ef-16f8-a945789701d3" 
            alt="SDG-Wheel" 
            className="af-class-image af-class-scroll-down-button animate-xrotate" />
        </HashLink>
      </span>) : (
        <span className="af-view">
        <HashLink smooth to={"/"+link} 
          className="af-class-scroll-down-button af-bottom-right animate-xappearfadein"
        > {/* af-class-home-hero w-inline-block */}
          <img src="images/SDG_Wheel_Transparent_WEB.png" 
            // data-w-id="c60ce000-668b-89ef-16f8-a945789701d3" 
            alt="SDG-Wheel" 
            className="af-class-image af-class-scroll-down-button animate-xrotate" />
        </HashLink>
      </span>
      )} 
  </span>
  );
}
ScrollSectionButton.defaultProps = {
  link: "#",
  webflowanimate: true,
};

ScrollSectionButton.propTypes = {
  link: PropTypes.string.isRequired,
  webflowanimate: PropTypes.bool,
};
