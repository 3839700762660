import React from "react";
// import {Helmet} from "react-helmet";
import NotFoundView from "./webflow/NotFoundView";

function NotFound(props) {
    return (
        <>
            <NotFoundView>
            </NotFoundView>
        </>
    );
}

export default NotFound;