/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";
// components

export default function CardBlogImageHorizontal({
  image,
  logo,
  title,
  description,
  link,
//  user,
//  username,
//  userlink,
//  date,
    theme,
    status,
    sdgs,
    founderImage,
    founderName,
}) {
//   const userComponent = (
//     <>
//       <img src={user} className="rounded-full mr-4 w-12 h-12" alt="..." />
//       <div className="flex-1">
//         <div className="justify-between items-center">
//           <h6 className="text-md font-bold uppercase mt-0 mb-0 text-blueGray-500">
//             {username}
//           </h6>
//           <small className="text-sm text-blueGray-500 mt-0">{date}</small>
//         </div>
//       </div>
//     </>
//   );
  const elementStyle = "px-4 py-8 flex flex-col items-stretch h-full place-content-between bg-cover bg-[url('../" + image +"')]";

  const classificationComponent = (
    <div>
      <div className="flex sm:flex-row flex-col justify-between items-center">
        <div className="items-center">
          <h6 className="text-md font-bold uppercase mt-0 mb-0 text-blueGray-500">
            {theme}
          </h6>
          <p className="text-sm font-bold uppercase mt-2 mb-0 text-blueGray-500">
            {status}
          </p>
        </div>
        <div className="mt-4 sm:mt-0 flex flex-wrap items-center justify-end">
          {sdgs.map((prop, key) => (
              <img key={key} src={prop} className="rounded-none mr-4 w-16 h-16" alt="..." />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-leapsolvefirst-masked w-full mb-6 shadow-lg rounded-lg">
        <div className="items-center flex flex-wrap">
          <div className="hidden px-6 py-2 lg:w-3/12 lg:flex w-full h-full items-stretch justify-center transition ease-in-out delay-150 hover:-translate-y-0.5 hover:scale-110 duration-300">
            <a href={link} target="_blank" rel="noreferrer" className="h-full w-full">
              <img
                src={image}
                className="rounded-lg object-contain h-full w-full max-h-[20vh]"
                alt="..."
              />
            </a>
          </div>

          <div className="px-4 relative w-full lg:w-6/12 items-stretch">
            <div className="px-4 py-8 flex flex-col items-stretch h-full place-content-between" >
            {/* <div className={elementStyle} > */}
              <div className="flex flex-row justify-between">
                <h3 className="text-3xl font-semibold text-blueGray-700 leading-tight self-center">
                  <a className="text-leapsolvesecond-normal no-underline hover:text-leapsolvethird-normal" href={link} target="_blank"
                    rel="noreferrer">{title}</a>    
                </h3>
                <div className="py-2 w-3/12 flex h-full items-stretch">
                  <a href={link} target="_blank" rel="noreferrer" className="h-full w-full">
                    <img
                      src={logo}
                      className="rounded-lg object-contain h-full w-full max-h-[8vh]"
                      alt="..."
                    />
                  </a>
                </div>
                
              </div>
              <p className="mt-6 text-lg leading-relaxed text-white">
                <span className="mr-2">{description}</span>
              </p>

              <div className="mt-2 w-full items-center">
                {classificationComponent}
              </div>
              {/* <div className="mt-6">
                {userlink && userlink.to ? (
                  <Link {...link} className="inline-flex items-center">
                    {userComponent}
                  </Link>
                ) : (
                  <a {...link} className="inline-flex items-center">
                    {userComponent}
                  </a>
                )}
              </div> */}
            </div>
          </div>

          <div className="hidden lg:flex flex-col lg:w-3/12 h-full items-center justify-center">
            <div className="flex px-6 py-2 mt-4 w-full h-full items-center justify-center">
                <img
                  src={founderImage}
                  className="rounded-lg object-contain h-40 w-40 max-h-[20vh]"
                  alt="..."
                />
            </div>
            <div className="flex items-center mt-4">
                <h3 className="text-lg font-semibold text-leapsolvesecond-normal leading-tight">
                  {founderName}
                </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
CardBlogImageHorizontal.defaultProps = {
//  link: {},
//  userlink: {},
};
CardBlogImageHorizontal.propTypes = {
  image: PropTypes.string,
  logo: PropTypes.string,
//  logo: PropTypes.string.isRequired,
  title: PropTypes.string,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  link: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  description: PropTypes.string,
  // properties to pass to the link object after the description
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  // NOTE: this will also apply as a wrapper for the title
  // this is an image for the user
  //user: PropTypes.string,
  //username: PropTypes.string,
  // properties to pass to the link object that
  // wrapps the user, date and username
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  //userlink: PropTypes.object,
  //date: PropTypes.string,
  theme: PropTypes.oneOf([
    "Decarbonization Enablers",
    "Industries Transformation",
    "Social Impact & Circularity",
  ]),
  status: PropTypes.oneOf([
    "Active Fund",
    "Former Investment",
  ]),
};
