/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import { HashLink } from 'react-router-hash-link';
// import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton';
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import PortfolioContent from '../../components/PortfolioContent'
import Collapsible from 'react-collapsible';
//import StrategiesContent from '../../components/StrategiesContent'
//import ShortStrategiesContent from '../../components/ShortStrategiesContent'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

const allCards = [
  {
    image:"images/4earth_bg.png",
    logo:"images/4earth_light.png",
    title:"4Earth",
    description: "Water water",
    link:"https://www.4earth.tech/",
    theme:"Industries Transformation",
    status:"Active Fund",
    sdgs:["images/E-WEB-Goal-09.png","images/E-WEB-Goal-11.png"],
    founderImage:"images/Jahanzeb_Khan_web.jpg",
    founderName:"Jahanzeb Khan",
  },
  {
    image:"images/50inTech_bg.png",
    logo:"images/50inTech.png",
    title:"50inTech",
    description: "50inTech aims for a 50% representation of women in technology by 2050. It's flagship SaaS platform enables the perfect match between talented people who self-identify as women and recuiters, and integrates with Applicant Tracking Systems. The company recently launched the 50inTech Gender Score, to help European Tech companies measure their level of gender-inclusion, communicate transparently, find actionable levers and progress faster.",
    link:"https://www.50intech.com/",
    theme:"Social Impact & Circularity",
    status:"Active Fund",
    sdgs:["images/E-WEB-Goal-05.png","images/E-WEB-Goal-08.png"],
    founderImage:"images/Caroline_Ramade_web.jpg",
    founderName:"Caroline Ramade",
  },
  {
    image:"images/impak_bg.png",
    logo:"images/logo_impakanalytics_light.png",
    title:"Impak Analytics",
    description: "Impact measurement",
    link:"https://www.impakanalytics.com/",
    theme:"Decarbonization Enablers",
    status:"Active Fund",
    sdgs:["images/E-WEB-Goal-13.png","images/E-WEB-Goal-17.png"],
    founderImage:"images/Paul_Allard_web.jpg",
    founderName:"Paul Allard",
  },
  {
    image:"images/suma_bg.jpg",
    logo:"images/Suma.png",
    title:"Suma Wealth",
    description: "LatinX",
    link:"https://sumawealth.com/",
    theme:"Social Impact & Circularity",
    status:"Active Fund",
    sdgs:["images/E-WEB-Goal-08.png","images/E-WEB-Goal-10.png"],
    founderImage:"images/Beatriz_Acevedo_web2.jpg",
    founderName:"Beatriz Acevedo",
  }
];

let Controller

class PortfolioView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../Portfolio')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = PortfolioView

        return Controller
      }

      throw e
    }
  }

  state = {
    noneOpen1: true,
    noneOpen2: true,
    noneOpen3: true,
  };

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    // const proxies = PortfolioView.Controller !== PortfolioView ? transformProxies(this.props.children) : {

    const closeFilterSection = () => {
      var fSection = document.getElementById("filterSection");
      fSection.classList.add("hidden");
    }
  
    const applyFilters = () => {
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));
      closeFilterSection();
    }

    const showFilters = () => {
      var fSection = document.getElementById("filterSection");
      if (fSection.classList.contains("hidden")) {
        fSection.classList.remove("hidden");
        fSection.classList.add("block");
      } else {
        fSection.classList.add("hidden");
      }
    }
  

    const buttonScrollToTopSectionProps = {
      link:"portfolio#top",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/leapsolve.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader />
              <div className="af-class-section af-class-home-hero af-class-wf-section bg-[url('../images/Background_Forest_Masked.jpg')] bg-cover animate-xappear min-h-[100vh]">
                <div className="lg:px-20 md:px-6 px-4 absolute right-0 top-[100px]">
                  <div className="flex justify-between items-center mb-4">
                    {/* <!-- filters Button (md and plus Screen) --> */}
                    <button onClick={showFilters} className="cursor-pointer text-white flex hover:text-leapsolvesecond-normal focus:ring focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-4 px-6 flex text-base leading-4 font-normal text-white justify-center items-center">
                    {/* <button onClick={showFilters} className="cursor-pointer text-white sm:flex hidden hover:text-leapsolvesecond-normal focus:ring focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 py-4 px-6 flex text-base leading-4 font-normal text-white justify-center items-center"> */}
                      <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 4V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M6 12V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 4V14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 18V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18 4V5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18 9V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <span className="sm:flex hidden">Filters</span>
                    </button>
                  </div>
                  {/* <!-- Filters Button (Small Screen) --> */}

                  {/* <button onClick={showFilters} className="cursor-pointer block sm:hidden hover:bg-gray-700 focus:ring focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 w-full bg-gray-800 flex text-base leading-4 font-normal text-white dark:text-gray-800 dark:bg-white dark:hover:bg-gray-100 justify-center items-center">
                    <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 12C7.10457 12 8 11.1046 8 10C8 8.89543 7.10457 8 6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6 4V8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M6 12V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 18C13.1046 18 14 17.1046 14 16C14 14.8954 13.1046 14 12 14C10.8954 14 10 14.8954 10 16C10 17.1046 10.8954 18 12 18Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 4V14" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12 18V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 9C19.1046 9 20 8.10457 20 7C20 5.89543 19.1046 5 18 5C16.8954 5 16 5.89543 16 7C16 8.10457 16.8954 9 18 9Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 4V5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M18 9V20" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Filters
                  </button> */}
                </div>

                <div id="filterSection" className="hidden relative md:py-10 lg:px-20 md:px-6 py-9 px-4 bg-leapsolvefirst-masked w-full">
                  {/* <!-- Cross button Code --> */}
                  <div onClick={closeFilterSection} className="cursor-pointer text-gray-800 dark:text-white absolute right-0 top-0 md:py-10 lg:px-20 md:px-6 py-9 px-4">
                    <svg className="lg:w-6 lg:h-6 w-4 h-4" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M25 1L1 25" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1 1L25 25" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>

                  {/* <!-- Status Section --> */}
                  <div>
                    <div className="flex space-x-2 text-gray-800 dark:text-white stroke-leapsolvesecond-normal">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                          <path d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M14 7H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M17 4V10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                      </svg>
                      <p className="lg:text-xl text-lg lg:leading-6 leading-5 font-medium text-leapsolvesecond-normal">Status</p>
                    </div>
                    <div className="flex space-x-8">
                      <div className="flex justify-center items-center">
                        <input className="w-4 h-4 mr-2" type="checkbox" id="LS" name="LS" value="LS" />
                        <div className="inline-block">
                          <div className="flex space-x-6 justify-center items-center">
                            <label className="mr-2 text-sm leading-3 font-normal dark:text-gray-300 text-gray-600">Active Fund</label>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <input className="w-4 h-4 mr-2" type="checkbox" id="LxL" name="LxL" value="LxL" />
                        <div className="inline-block">
                          <div className="flex space-x-6 justify-center items-center">
                            <label className="mr-2 text-sm leading-3 font-normal dark:text-gray-300 text-gray-600">Former Investment</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Theme Section --> */}
                  <div className="pt-8">
                    <div className="flex space-x-2 text-gray-800 dark:text-white stroke-leapsolvethird-normal">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.8">
                          <path d="M9 4H5C4.44772 4 4 4.44772 4 5V9C4 9.55228 4.44772 10 5 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 14H5C4.44772 14 4 14.4477 4 15V19C4 19.5523 4.44772 20 5 20H9C9.55228 20 10 19.5523 10 19V15C10 14.4477 9.55228 14 9 14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M19 14H15C14.4477 14 14 14.4477 14 15V19C14 19.5523 14.4477 20 15 20H19C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M14 7H20" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M17 4V10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                      </svg>
                      <p className="lg:text-xl text-lg lg:leading-6 leading-5 font-medium text-leapsolvethird-normal">Theme</p>
                    </div>
                    <div className="flex space-x-8">
                      <div className="flex justify-center items-center">
                        <input className="w-4 h-4 mr-2" type="checkbox" id="LS" name="LS" value="LS" />
                        <div className="inline-block">
                          <div className="flex space-x-6 justify-center items-center">
                            <label className="mr-2 text-sm leading-3 font-normal dark:text-gray-300 text-gray-600">Decarbonization Enablers</label>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <input className="w-4 h-4 mr-2" type="checkbox" id="LxL" name="LxL" value="LxL" />
                        <div className="inline-block">
                          <div className="flex space-x-6 justify-center items-center">
                            <label className="mr-2 text-sm leading-3 font-normal dark:text-gray-300 text-gray-600">Industries Transformation</label>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center">
                        <input className="w-4 h-4 mr-2" type="checkbox" id="LxL" name="LxL" value="LxL" />
                        <div className="inline-block">
                          <div className="flex space-x-6 justify-center items-center">
                            <label className="mr-2 text-sm leading-3 font-normal dark:text-gray-300 text-gray-600">Social Impact & Circularity</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Apply Filter Button (Large Screen) --> */}

                  <div className="hidden md:block absolute right-0 bottom-0 md:py-4 lg:px-20 md:px-6 py-4 px-4">
                    <button onClick={applyFilters} className="hover:bg-gray-700 focus:ring focus:ring-offset-2 focus:ring-gray-800 text-base leading-4 font-medium py-4 px-10 text-white">Apply Filter</button>
                  </div>

                  {/* <!-- Apply Filter Button (Table or lower Screen) --> */}

                  <div className="block md:hidden w-full mt-10">
                    <button onClick={applyFilters} className="w-full hover:bg-gray-700 focus:ring focus:ring-offset-2 focus:ring-gray-800 text-base leading-4 font-medium py-4 px-10 text-white">Apply Filter</button>
                  </div>
                </div>

                <PortfolioContent 
                      headingBadge={{
                        title:"Investments",
                        //subtitle:"this is the sub",
                        description:"Meet the Startups and Founders we are proud to support",
                        alignment:"center",
                        color:"leapsolvesecond",
                        whiteContent:true                                              
                      }}
                      cards={allCards}
                    />
                    
                <ScrollTopButton {...buttonScrollToTopSectionProps} />

              </div>

              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default PortfolioView

/* eslint-enable */