/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ButtonFullEffects from '../../components/webflow/ButtonFullEffects'
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import TogetherContent from '../../components/TogetherContent'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton'
import MultipleProfilesWrapper from '../../components/notus/MultipleProfilesWrapper'

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

const profilesProps = {
  nbCols: 2,
  image: "",
  cards: [
    {
      image: "images/Lionel_web.jpg",
      title: "Lionel Auguste",
      description: "Co-Founder & Managing Partner",
      affiliation: "",
      // list: [
      //   {
      //     icon: "fas fa-arrow-up-right-dots",
      //     color: "lightBlue",
      //     text: "Accute strategist",
      //   },
      //   {
      //     icon: "fas fa-bolt-lightning",
      //     color: "emerald",
      //     text: "Fast learner",
      //   },
      //   { icon: "fas fa-thumbs-up", color: "red", text: "Relentless optimist" },
      // ],
      link: "https://www.linkedin.com/in/lionelauguste",
      // // dropdown: {
      // //   icon: "fas fa-cog",
      // //   button: { color: "white" },
      // //   items: [
      // //     { href: "#pablo", children: "Edit Profile" },
      // //     { href: "#pablo", children: "Settings" },
      // //     { href: "#pablo", children: "Log out" },
      // //   ],
      // },
    },
    {
      image: "images/Axi_1208_1221low.jpg",
      title: "Fabien Couderc",
      description: "Co-Founder & Managing Partner",
      affiliation: "",
      // list: [
      //   {
      //     icon: "fas fa-chart-bar",
      //     color: "lightBlue",
      //     text: "Successful entrepreneur",
      //   },
      //   { icon: "fas fa-atom", color: "emerald", text: "Problem solver" },
      //   { icon: "fas fa-running", color: "red", text: "Full throttle ethos" },
      // ],
      link: "https://www.linkedin.com/in/fabiencouderc",
      // dropdown: {
      //   icon: "fas fa-cog",
      //   button: { color: "white" },
      //   items: [
      //     { href: "#pablo", children: "Edit Profile" },
      //     { href: "#pablo", children: "Settings" },
      //     { href: "#pablo", children: "Log out" },
      //   ],
      // },    
    },
  ],
  color: "black",
}

const profilesAdvisorsProps = {
  nbCols: 3,
  image: "",
  cards: [
    {
      image: "images/CS_debout_tete_web.jpg",
      title: "Carol Sirou",
      description: "Strategic Advisor, Advisory Board",
      affiliation: "CEO at Ethifinance",
      // list: [
      //   {
      //     icon: "fas fa-arrow-up-right-dots",
      //     color: "lightBlue",
      //     text: "Accute strategist",
      //   },
      //   {
      //     icon: "fas fa-bolt-lightning",
      //     color: "emerald",
      //     text: "Fast learner",
      //   },
      //   { icon: "fas fa-thumbs-up", color: "red", text: "Relentless optimist" },
      // ],
      link: "https://www.linkedin.com/in/carol-sirou",
      // // dropdown: {
      // //   icon: "fas fa-cog",
      // //   button: { color: "white" },
      // //   items: [
      // //     { href: "#pablo", children: "Edit Profile" },
      // //     { href: "#pablo", children: "Settings" },
      // //     { href: "#pablo", children: "Log out" },
      // //   ],
      // },
    },
    {
      image: "images/chris_profile_image_web.jpg",
      title: "Chris Cormack",
      description: "Venture Advisor, Decarbonization",
      affiliation: "Co-Founder & MD at The Quant Foundry",
      // list: [
      //   {
      //     icon: "fas fa-chart-bar",
      //     color: "lightBlue",
      //     text: "Successful entrepreneur",
      //   },
      //   { icon: "fas fa-atom", color: "emerald", text: "Problem solver" },
      //   { icon: "fas fa-running", color: "red", text: "Full throttle ethos" },
      // ],
      link: "https://www.linkedin.com/in/christophercormack",
      // dropdown: {
      //   icon: "fas fa-cog",
      //   button: { color: "white" },
      //   items: [
      //     { href: "#pablo", children: "Edit Profile" },
      //     { href: "#pablo", children: "Settings" },
      //     { href: "#pablo", children: "Log out" },
      //   ],
      // },    
    },
    {
      image: "images/allie_web.jpg",
      title: "Allie Sharma",
      description: "Venture Advisor, Health & Social Impact",
      affiliation: "Psychiatrist & Health Strategist",
      // list: [
      //   {
      //     icon: "fas fa-chart-bar",
      //     color: "lightBlue",
      //     text: "Successful entrepreneur",
      //   },
      //   { icon: "fas fa-atom", color: "emerald", text: "Problem solver" },
      //   { icon: "fas fa-running", color: "red", text: "Full throttle ethos" },
      // ],
      link: "https://www.linkedin.com/in/dralliesharmapsychiatry",
      // dropdown: {
      //   icon: "fas fa-cog",
      //   button: { color: "white" },
      //   items: [
      //     { href: "#pablo", children: "Edit Profile" },
      //     { href: "#pablo", children: "Settings" },
      //     { href: "#pablo", children: "Log out" },
      //   ],
      // },    
    },
  ],
  color: "black",
}

const profilesAllProps = {
  nbCols: 3,
  image: "",
  cards: [
    {
      image: "images/Lionel_web.jpg",
      title: "Lionel Auguste",
      description: "Managing Partner",
      affiliation: "Strategist, Entrepreneur, Investor",
      link: "https://www.linkedin.com/in/lionelauguste",
    },
    {
      image: "images/Fabien_web.jpg",
      title: "Fabien Couderc",
      description: "Managing Partner",
      affiliation: "Technologist, Entrepreneur, Strategist",
      link: "https://www.linkedin.com/in/fabiencouderc",
    },
    // {
    //   image: "images/Sophie_web.jpg",
    //   title: "Sophie Peresson",
    //   description: "Partner, Investor Relations",
    //   affiliation: "Department Of Energy",
    //   link: "https://www.linkedin.com/in/sophieperesson/",
    // },
    {
      image: "images/Chris_web.jpg",
      title: "Chris Cormack",
      description: "Advisor, Decarbonization",
      affiliation: "Co-Founder & MD at The Quant Foundry",
      link: "https://www.linkedin.com/in/christophercormack",
    },
    // {
    //   image: "images/Rohan_web.jpg",
    //   title: "Rohan Kapoor",
    //   description: "Advisor, Supply Chains",
    //   affiliation: "CRO at Snapper Future Tech",
    //   link: "https://www.linkedin.com/in/fundraising26/",
    // },
    {
      image: "images/Allie_web2.jpg",
      title: "Allie Sharma",
      description: "Advisor, Health & Social Impact",
      affiliation: "Psychiatrist & Health Strategist",
      link: "https://www.linkedin.com/in/dralliesharmapsychiatry",
    },
    {
      image: "images/Carol_web.jpg",
      title: "Carol Sirou",
      description: "Strategic Advisor",
      affiliation: "CEO at Ethifinance",
      link: "https://www.linkedin.com/in/carol-sirou",
    },
  ],
  color: "black",
}



let Controller

class AboutUsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../AboutUs')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AboutUsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    // const proxies = AboutUsView.Controller !== AboutUsView ? transformProxies(this.props.children) : {

    // }

    const buttonTogetherProps = {
      image:"pinch-easy-white.png",
      displayText:"Investment Themes",
      link: "/strategy",
    }
    const buttonPurposeGrowthProps = {
      image:"sustainable-growth-white.png",
      displayText:"Purpose & Growth",
      link: "/impact",
    }

    const buttonScrollToWhoSectionProps = {
      link:"aboutus#team",
      webflowanimate:false,
    }

    const buttonScrollToEthosSectionProps = {
      link:"aboutus#ethos",
      webflowanimate:false,
    }

    const buttonScrollToTopSectionProps = {
      link:"aboutus#top",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/leapsolve.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader />
              <div id="manifesto" className="af-class-section af-class-home-hero af-class-wf-section bg-[url('../images/Background_Hills_Masked.jpg')] bg-cover animate-xappear min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container">
                  <div className="af-class-mg-bottom-large-72px">
                    <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                      <h2 className="af-class-display-2 inline text-leapsolvesecond-normal font-bold text-center animate-xappearfadein">Manifesto</h2>                
                      <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                        <div className="af-class-fill-block af-class-bg-neutral-100 bg-leapsolvesecond-normal opacity-50" />
                      </div> 
                    </div> 
                    <div className="af-class-paragraph-large af-class-manifesto-hero-paragraph text-gray-300 hover:text-gray-500 animate-xappearfadein duration-150">
                      <div className="hover:text-white duration-500">
                        <span className="">
                          We founded Altalurra as impact-driven optimists who see technology as a force for good and believe that the startup ecosystem will have a positive global impact.&nbsp;
                        </span>
                        <span className="">
                          Our objective is to partner early with inspiring, ambitious tech founders: renegades who are reimagining the world and shaping it their way. 
                          {/* Breakthrough underserved businesses are starting and scaling with increasing frequency, powered by unparalleled engineering talent. */}
                        </span> 
                      </div>
                      <div className="hover:text-white duration-500">
                        <span className="">
                          <br />
                          We believe in people; their experience, skills, network, and domain-specific knowledge as much as their self-awareness and their ability to grow. As a diverse team, we value diversity and strive to consider all startups and founders without bias. 
                        </span>
                      </div>
                      <div className="hover:text-white duration-500">
                        <span className="">
                          <br />
                          Altalurra wants to boost your mission, because it is ours too. We recognize that venture capital would not exist without founders like you, who put everything on the line. We are thrilled when you choose to work with us and honored to support the realization of your dream.&nbsp;
                        </span> 
                        {/* <span className="">
                          We come to assist you, as much as you desire to. Through our backgrounds in technology, in ESG & impact, and in finance, it is our own intent to help you succeed and to bring focus on sustainable businesses as the norm.
                        </span>  */}
                      </div>
                      <div className="hover:text-white duration-500">
                        <p className="mb-4 mt-8">
                          Partnering with us, you can expect
                        </p>
                        <div className="flex flex-cols flex-wrap gap-y-4">
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-leapsolvesecond-normal text-left duration-150">Alignment of interests</span></li></ul></div>
                            <div className="flex w-full">We commit to support your impact-driven business with unbiased counsel, and honesty to help your goals.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-leapsolvesecond-normal text-left duration-150">Boutique service</span></li></ul></div>
                            <div>We are a small, highly collaborative team who work across the portfolio no matter how big or small the task. We do the research, deep diving into your startup, products and ecosystem. Some might come in with a prescriptive approach, whereas we have a uniquely bespoke process tailored to every situation. Trust is essential and every relationship matters.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-leapsolvesecond-normal text-left duration-150">Growth mindset</span></li></ul></div>
                            <div>The founding members of Altalurra have successfully grown multiple companies from the ground up. We know what works and what doesn't, and will help generate revenue, and quickly, without impeding your mission or imposing a financing trajectory.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-leapsolvesecond-normal text-left duration-150">Low friction</span></li></ul></div>
                            <div>We are humble, curious, open and straightforward. We appreciate your time and commit to a simple, transparent business process and quick turnarounds.</div>
                          </div>
                          <div className="flex w-full flex-col lg:flex-row gap-x-1 gap-y-1 group">                              
                            <div className="flex-none w-[300px]"><ul className="list-disc list-inside p-0 m-0 space-0"><li><span className="font-bold group-hover:text-leapsolvesecond-normal text-left duration-150">Long term engagement</span></li></ul></div>
                            <div>Success is not built overnight, and venture capital is a business that rewards patience. We understand that and are long term partners for both you and your business.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-_2-buttons-wrap flex-col md:flex-row gap-x-8 gap-y-3 animate-xappearfadein">
                    <ButtonFullEffects {...buttonTogetherProps} />
                    <ButtonFullEffects {...buttonPurposeGrowthProps} />
                  </div>
                </div>
                <ScrollSectionButton {...buttonScrollToWhoSectionProps} />
              </div>
              <div id="team" className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section bg-leapsolvefirst-masked min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-mg-bottom-large-72px mb-0">
                      <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                        <h2 className="af-class-display-2 inline text-leapsolvesecond-normal font-bold text-center animate-xappearfadein">Meet the Team</h2>                
                        <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                          <div className="af-class-fill-block af-class-bg-neutral-100 bg-leapsolvesecond-normal opacity-50" />
                        </div> 
                      </div> 
                    </div>
                  </div>
                </div>
                {/* <div className="af-class-container-default max-w-[90vw] animate-xappearfadein overscroll-y-auto">
                  <MultipleProfilesWrapper {...profilesProps} />
                </div>
                <div className="af-class-container-default max-w-[90vw] animate-xappearfadein overscroll-y-auto">
                  <MultipleProfilesWrapper {...profilesAdvisorsProps} />
                </div> */}
                <div className="af-class-container-default max-w-[90vw] animate-xappearfadein overscroll-y-auto">
                  <MultipleProfilesWrapper {...profilesAllProps} />
                </div>
                <ScrollSectionButton {...buttonScrollToEthosSectionProps} />
              </div>
              <div id="ethos" className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section bg-[url('../images/Background_Mountain_Masked.jpg')] bg-cover min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-3 w-container content-center items-center place-content-center">
                  <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleondark text-center">Our Ethos</h2>                
                  <div data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" className="mt-8" >
                    <TogetherContent />
                  </div>
                </div>
                <ScrollTopButton {...buttonScrollToTopSectionProps} />
              </div>
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default AboutUsView

/* eslint-enable */