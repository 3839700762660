/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import SiteHeader from '../../components/SiteHeader'
import SiteFooter from '../../components/SiteFooter'
import ScrollSectionButton from '../../components/webflow/ScrollSectionButton'
import ScrollTopButton from '../../components/ScrollTopButton'
// import HubspotForm from 'react-hubspot-form';
// import Newsletter from '../../components/openpro/Newsletter'
import ButtonSimpleEffectsExternal from "../../components/webflow/ButtonSimpleEffectsExternal";

const scripts = [
  { loading: fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=61e48a462079c356c6452042").then(body => body.text()), isAsync: false },
  { loading: fetch("js/webflow.js").then(body => body.text()), isAsync: false },
]

let Controller

class NewsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../News')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = NewsView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6242288191da2b50c6937890'
    htmlEl.dataset['wfSite'] = '61e48a462079c356c6452042'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
    // const proxies = NewsView.Controller !== NewsView ? transformProxies(this.props.children) : {

    // }

    const buttonScrollToWhoSectionProps = {
      link:"news#who",
      webflowanimate:false,
    }

    const buttonScrollToTopSectionProps = {
      link:"news#top",
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/leapsolve.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div className="af-class-page-wrapper">
              <SiteHeader />
              <div id="subscribe" className="af-class-section af-class-home-hero af-class-wf-section bg-[url('../images/Background_Mountain_Masked.jpg')] bg-cover animate-xappear min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-default w-container">
                  <div>
                    <div className="af-class-mg-bottom-large-72px mb-0">
                      <div className="af-class-title-wrapper af-class-mg-bottom-24px">
                        <h2 className="af-class-display-2 inline text-leapsolvesecond-normal font-bold text-center animate-xappearfadein">Want to know more?</h2>                
                        <div className="af-class-inline af-class-underline-bg af-class-mg-bottom-mb-32px animate-xappearxfadein">
                          <div className="af-class-fill-block af-class-bg-neutral-100 bg-leapsolvesecond-normal opacity-50" />
                        </div> 
                      </div> 
                    </div>
                    <p className="af-class-paragraph-large af-class-home-hero-paragraph animate-xappearfadein">
                      Browse through our notes and blogs
                      <ul className="list-disc list-inside p-0 m-0 space-0">
                        <li>
                          <ButtonSimpleEffectsExternal
                            link={"https://impact.altalurra.com/notes/foundations-of-impact-investing"}
                            displayText={"Which foundations for impact investing?"}
                            onDark={true}
                            appearOnScroll={false}
                            flexText={true}
                            displayArrow={false}
                          />
                        </li>
                        <li>
                          <ButtonSimpleEffectsExternal
                            link={"https://impact.altalurra.com/notes/unpacking-impact-assessment"}
                            displayText={"Unpacking impact for a 360° assessement"}
                            onDark={true}
                            appearOnScroll={false}
                            flexText={true}
                            displayArrow={false}
                          />
                        </li>
                      </ul>                    </p>
                    <p className="af-class-paragraph-large af-class-home-hero-paragraph animate-xappearfadein">
                      Join our newsletter to receive information and updates.
                    </p>
                    {/* <div className="pt-8" data-w-id="f4e69ca9-4bf5-636d-0b9c-3702a8342414" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}>
                    <Newsletter />
                    </div> */}
                    {/* <div className="min-w-full place-content-center text-white">
                      <HubspotForm
                        portalId='22435899'
                        formId='1e615abf-11b4-4430-8f02-7ab1f3823278'
                        onSubmit={() => console.log('Submit!')}
                        onReady={(form) => console.log('Form ready!')}
                        loading={<div>Loading...</div>}
                      />
                    </div> */}
                  </div>
                </div>
                {/* <div className="af-class-container-default max-w-[90vw] animate-xappearfadein overscroll-y-auto">
                  <MultipleProfilesWrapper {...profilesProps} />
                </div>
                <div className="af-class-container-default max-w-[90vw] animate-xappearfadein overscroll-y-auto">
                  <MultipleProfilesWrapper {...profilesAdvisorsProps} />
                </div> */}

                {/* <ScrollSectionButton {...buttonScrollToWhoSectionProps} /> */}
              </div>
              {/* <div id="who" className="af-class-section af-class-bg---90vh af-class-contact af-class-wf-section bg-[url('../images/Background_Mountain_Masked.jpg')] bg-cover min-h-[100vh] content-center items-center place-content-center">
                <div className="af-class-container-3 w-container content-center items-center place-content-center">
                  <h2 data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" style={{WebkitTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} className="af-class-display-2 af-class-mg-bottom-0px af-class-titleondark text-center">Our Ethos</h2>                
                  <div data-w-id="3eb5ca53-81ff-7c0d-f71c-03856ef027cb" className="mt-8" >
                    <TogetherContent />
                  </div>
                </div>
                <ScrollTopButton {...buttonScrollToTopSectionProps} />
              </div> */}
              <SiteFooter />
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default NewsView

/* eslint-enable */