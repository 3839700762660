import React from "react"

// @notus-pro/react
import MultipleFlippingCardsWrapper from "./notus/MultipleFlippingCardsWrapper"

const props = {
  nbCols: 3,
  image: "",
  cards: [
    {
      icon: "far fa-image",
      color: "transparentondark",
      title: "You can count on us",
      isFixed: true,
      description:
        "",
      // link: { href: "strategy.html", children: "Find out how" },
    },
    {
      icon: "fas fa-hat-wizard",
      color: "dark",
      title: "Part of your pack",
      isFixed: false,
      description:
        "Team effort pays out. Trust creates value, not politics. Filling the gaps is what we do. Whatever it takes, with ethics.",
      // link: { href: "/strategy#strategies", children: "Get into more details" },
    },
    {
      icon: "fas fa-dungeon",
      color: "dark",
      title: "Present just as you need",
      isFixed: false,
      description:
        "As the entrepreneur and vision holder, you drive, we assist. We aim at being your sparring partners. As an investor, you make us accountable. We provide the context.",
      // link: { href: "impact", children: "Modus operandi" },
    },
    {
      icon: "fas fa-trophy",
      color: "dark",
      title: "Builders",
      isFixed: false,
      description:
        "You have been told that it is not possible? We have been there. Stamina, focus and discipline are our success factors.",
      // link: { href: "team", children: "Meet the team" },
    },
    {
      icon: "fas fa-trophy",
      color: "dark",
      title: "Strategic thinkers",
      isFixed: false,
      description:
        "Never forget about the long-term vision but adapt to the ecosystem. Figuring out the steps to get there can be hard. This is where our failures and successes can help you.",
      // link: { href: "team", children: "Meet the team" },
    },
    {
      icon: "fas fa-dungeon",
      color: "transparentondark",
      title: "on our expertise",
      isFixed: true,
      description:
        "",
      // link: { href: "impact", children: "Modus operandi" },
    },
    {
      icon: "fas fa-dungeon",
      color: "transparentondark",
      title: "and our shared goals",
      isFixed: true,
      description:
        "",
      // link: { href: "impact", children: "Modus operandi" },
    },
    {
      icon: "fas fa-trophy",
      color: "dark",
      title: "Positive impact",
      isFixed: false,
      description:
        "We strongly desire to foster change in behaviors, leading by example. We started this as we believe that we will generate a broader impact by joining forces with your team.",
      // link: { href: "team", children: "Meet the team" },
    },
    {
      icon: "fas fa-trophy",
      color: "dark",
      title: "Value creation with ethics",
      isFixed: false,
      description:
        "We have families. We care about the future. Yet as you do, we seek to create monetary value. Together, we will ensure purpose and growth align.",
      // link: { href: "team", children: "Meet the team" },
    },
  ],
  color: "black",
}

export default function TogetherContent() {
  return (
    <MultipleFlippingCardsWrapper {...props} />
  )
}