import React from "react";
import PropTypes from "prop-types";

// components
import HeadingBadge from "./notus/HeadingBadge.js";
import CardBlogImageHorizontal from "./notus/CardBlogImageHorizontal.js";

export default function PortoflioContent({ headingBadge, cards, filters }) {
  return (
    <>
      <div className="py-12 blog-3">
        <div className="container mx-auto px-4">
          <div className="mb-12">
            {headingBadge && <HeadingBadge {...headingBadge} />}
          </div>
          {cards.map((prop, key) => {
            return <CardBlogImageHorizontal {...prop} key={key} />;
          })}
        </div>
      </div>
    </>
  );
}

PortoflioContent.defaultProps = {
  cards: [],
  headingBadge: null,
  filters: [],
};

PortoflioContent.propTypes = {
  // prop to pass to the HeadingBadge component
  headingBadge: PropTypes.object,
  // it is an array of objects which
  // are represented by the props
  // that can be passed to the CardBlogImageHorizontal,
  // so please check that one out
  cards: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.arrayOf(PropTypes.string),
};
